.footer-main-input {
    height: 3.9rem;
    width: 37.7rem;
    height: 4.4rem;
    display: flex;
    border-radius: 3px;
    margin-bottom: 2.5rem;
  }
  .footer-main-input > input {
    border: none;
    height: 3.9rem;
    width: 100%;
  }
  .main-footer {
    background: #eeeffb;
  }
  .footer-main {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .footer-main-middle {
    margin-top: 9.1rem;
  }
  .footer-main-input > button {
    outline: none;
    width: 13.5rem;
    height: 3.9rem;
    background: #fb2e86;
    border-radius: 3px;
    font-family: lato;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #eeeffb;
    border: none;
  }
  .footer-contact > p {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #8a8fb9;
  }
  
  .footer-title {
    font-family: Josefin Sans;
    font-size: 2.2rem;
    line-height: 2.6rem;
    color: #000000;
    margin-bottom: 4.2rem;
  }
  .footer-content-left > img {
    margin-bottom: 3.1rem;
  }
  .footer-content {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #8a8fb9;
    margin-bottom: 2.1rem;
  }