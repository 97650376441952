.landing-master {
  background: #f2f0ff;
}
.landing-main {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.landing-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 2.5;
}
.landing-top {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  color: var(--primary-button-color);
}
.landing-sub {
  font-family: Josefin Sans;
  font-size: 5.3rem;
  line-height: 8.2rem;
  color: #000000;
  font-weight: 600;
}
.landing-content {
  font-size: 1.6rem;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  color: #8a8fb9;
}
.landing-button-left{
  border: var(--primary-button-color);
  background-color: white;
  border-radius: 2px;
  width: 16.3rem;
  height: 5rem;
  font-family: Josefin Sans;
  font-size: 1.7rem;
  color: #000000;
  margin-top: 4.3rem;
  margin-right: 2rem;
  cursor: pointer;
}
.landing-button {
  border: none;
  background: var(--primary-button-color);
  border-radius: 2px;
  width: 16.3rem;
  height: 5rem;
  font-family: Josefin Sans;
  font-size: 1.7rem;
  color: #ffffff;
  margin-top: 4.3rem;
  cursor: pointer;
}
.landing-right {
  flex: 0.5;
}
.landing-right-img {
  height: 62.9rem;
  width: 62.9rem;
}
.light-left {
  position: absolute;
}
